import { Paper, Image, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import AddSourceButton from '../AddSourceButton';
import EmptyStateLogo from 'assets/empty-state-logo.svg';
import styles from './EmptyState.module.scss';

const EmptyState = (props) => {
  const {
    isDisabled,
    clickCallback,
    text,
    hideButton,
    textStyles,
    containerStyle,
    showUpdateModal,
    providerFromProp,
    children,
    teamId,
    reAuthCompleted
  } = props;

  return (
    <Paper className={containerStyle || styles.paper}>
      <Image src={EmptyStateLogo} className={styles.image} alt='Empty State Logo' />
      <Text className={textStyles || styles.text}>
        {text || 'Add your first source from this connection'}
      </Text>
      {hideButton ? null : (
        <AddSourceButton
          showUpdateModal={showUpdateModal}
          isDisabled={isDisabled}
          clickCallback={clickCallback ? clickCallback : () => {}}
          providerFromProp={providerFromProp}
          teamIdFromProps={teamId}
          reAuthCompleted={reAuthCompleted}
        />
      )}
      {children}
    </Paper>
  );
};

EmptyState.defaultProps = {
  isDisabled: false,
  clickCallback: () => null,
  text: '',
  hideButton: false,
  textStyles: '',
  containerStyle: '',
  providerFromProp: '',
  children: null,
  teamId: ''
};

EmptyState.propTypes = {
  isDisabled: PropTypes.bool,
  clickCallback: PropTypes.func,
  text: PropTypes.string,
  hideButton: PropTypes.bool,
  textStyles: PropTypes.string,
  containerStyle: PropTypes.string,
  showUpdateModal: PropTypes.bool,
  providerFromProp: PropTypes.string,
  children: PropTypes.node,
  teamId: PropTypes.string,
  reAuthCompleted: PropTypes.bool
};

export default EmptyState;
