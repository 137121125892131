import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Loader, Modal, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useUpdateOnboardingMutation } from 'redux/services/on-boarding';
import userApi from 'redux/services/user';
import { useLogoutMutation } from 'redux/services/user';
import Header from './Components/Header/View';
import OnboardingBodyComponent from './Components/Body/View';
import { STEPS } from './constants';
import { NAVBAR_ROUTES } from 'utils/constants';

const OnBoarding = () => {
  const [step, setStep] = useState('');
  const [active, setActive] = useState(0);
  const [provider, setProvider] = useState('');
  const [syncStarted, setSyncStarted] = useState(false);
  const navigate = useNavigate();
  const [logoutUser] = useLogoutMutation();
  const { user } = useSelector((state) => state.user);
  const [updateOnboarding, { isLoading }] = useUpdateOnboardingMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('syncStarted')) {
      setSyncStarted(true);
      // if connector connected and sync started, will wait for sync to complete before moving to next step
      // else rapid form will show empty selection ui
      if (
        user?.connections?.some((conn) => conn.provider === localStorage.getItem('syncStarted'))
      ) {
        //check for connection,if yes romove local state
        localStorage.removeItem('syncStarted');
        setSyncStarted(false);
      } else {
        //if not, poll user api and re-iterate logic
        setTimeout(() => {
          dispatch(userApi.util.invalidateTags(['User']));
        }, 3000);
      }
      return;
    }

    if (
      Object.values(user).length &&
      (!user?.isAdmin ||
        !user?.isInstaller ||
        user.onboarding?.installer?.status === 'complete' ||
        user.onboarding?.installer?.status === 'not_to_be_shown')
    ) {
      navigate(NAVBAR_ROUTES.ASK_ALBUS);
    }

    if (user?.onboarding?.installer?.steps) {
      const obj = user?.onboarding?.installer?.steps;

      if (!obj.form) {
        setStep(STEPS.ZERO);
      } else if ((obj.connectApp && user?.connections?.length === 0) || !obj.connectApp) {
        setStep(STEPS.CONNECT_AN_APP);
        setActive(1);
      } else if (!obj.addSource) {
        setStep(STEPS.ADD_A_SOURCE);
        setActive(2);
      } else if (!obj.askQuestion) {
        setStep(STEPS.ASK_A_QUESTION);
        setActive(3);
      }
    }

    if (
      Object.values(user).length &&
      user.onboarding?.installer &&
      (!user.onboarding.installer.steps || !user.onboarding.installer.status)
    ) {
      setStep(STEPS.ZERO);
      setActive(0);
    }

    if (!provider && user) {
      setProvider(user?.connections?.[0]?.provider);
    }
  }, [user, localStorage.getItem('syncStarted')]);

  const handleStepChange = (step, provider, back) => {
    setStep(step);
    if (provider) {
      setProvider(provider);
    }

    if (back) {
      setActive((prev) => prev - 1);
      return;
    }

    setActive((prev) => prev + 1);
  };

  const handleAboutSubmit = (step, value) => {
    setStep(step);
    updateOnboarding(value);
    setActive((prev) => prev + 1);
  };

  return (
    <>
      {syncStarted && (
        <Modal closeOnClickOutside={false} centered withCloseButton={false} size='md' opened={true}>
          <Flex direction='column' h={240} justify='center' align='center' gap={40}>
            <Text>Please wait while we establish the connection.</Text>
            <Loader />
          </Flex>
        </Modal>
      )}
      <Flex direction='row' w='100%' justify='center'>
        <Header
          active={active}
          setActive={setActive}
          logout={logoutUser}
          showLeftPanel={active === 0 || active === 2}
        />
        <OnboardingBodyComponent
          step={step}
          isLoading={isLoading}
          handleAboutSubmit={handleAboutSubmit}
          handleStepChange={handleStepChange}
          provider={provider}
          user={user}
          active={active}
        />
      </Flex>
    </>
  );
};

export default OnBoarding;
