import { shape } from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox, Tooltip } from '@mantine/core';
import classnames from 'classnames';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import { LATEST_MODEL_UPDATE_YEAR, LATEST_MODEL_UPDATE_MONTH } from 'utils/constants';
import styles from './SearchTab.module.scss';

const SearchTab = ({ form }) => {
  const { planInfo } = useSelector((state) => state.plan);

  const handleCheckboxChange = (event, fieldName) => {
    const updatedSearch = { ...form.values.search };
    updatedSearch[fieldName] = event.target.checked;
    form.setFieldValue('search', updatedSearch);
  };

  return (
    <div className={styles.container}>
      {!form.values.search.ai && !form.values.search.training && (
        <div className={styles.errorMessage}>
          <ErrorIcon />
          <span>
            To install the widget, you need to select at least one data source for search.
          </span>
        </div>
      )}
      <div className={styles.title}>Search</div>
      <div className={styles.subTitle}>
        When someone asks a question on your widget, Albus should answer using:
      </div>
      <Checkbox
        radius='sm'
        size='xs'
        mb={20}
        label='Wiki data'
        description='Answer using data sources like company policies, Slack conversations and team documentation across 100+ workplace apps including Google Drive, Slack and Jira.'
        classNames={{
          root: styles.checkbox,
          label: styles.checkboxLabel,
          description: styles.description,
          input: classnames({ [styles.checked]: form.values.search.training })
        }}
        checked={form.values.search.training}
        onChange={(event) => handleCheckboxChange(event, 'training')}
      />
      <Tooltip
        w={274}
        disabled={planInfo.hasSubscription}
        label='To enable AI search capabilities, upgrade to a paid plan.'
      >
        <Checkbox
          radius='sm'
          size='xs'
          label={`Online data up to ${LATEST_MODEL_UPDATE_YEAR}`}
          description={`Answer questions using GPT-4o that is trained on online, public data up to ${LATEST_MODEL_UPDATE_MONTH}.`}
          disabled={!planInfo.hasSubscription}
          classNames={{
            root: styles.checkbox,
            label: classnames(
              { [styles.disabled]: !planInfo.hasSubscription },
              styles.checkboxLabel
            ),
            description: classnames(
              { [styles.disabled]: !planInfo.hasSubscription },
              styles.description
            ),
            input: classnames({ [styles.checked]: form.values.search.ai })
          }}
          checked={form.values.search.ai}
          onChange={(event) => handleCheckboxChange(event, 'ai')}
        />
      </Tooltip>
    </div>
  );
};

SearchTab.propTypes = {
  form: shape({}).isRequired
};

export default SearchTab;
