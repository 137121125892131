import { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import pluralize from 'pluralize';
import {
  Box,
  Button,
  Checkbox,
  Combobox,
  Flex,
  Group,
  Radio,
  Text,
  Textarea,
  TextInput,
  Tooltip
} from '@mantine/core';
import FooterConfirmationBar from 'components/FooterConfirmationBar';
import CustomOptionMultiSelect from 'components/CustomOptionMultiSelect';
import CustomApiKeySection from './CustomApiKeySection';
import CustomizedApiInfo from './CustomizedApiInfo';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import { LATEST_MODEL_UPDATE_YEAR, LATEST_MODEL_UPDATE_MONTH } from 'utils/constants';
import styles from './styles.module.scss';

const SelectItem = (props) => {
  const { label, sourcesCount, ...others } = props;
  return (
    <Combobox.Option {...others} className={styles.optionItem}>
      <Flex justify='space-between' align='center' className={styles.item}>
        <Group justify='left' spacing={4}>
          <div>
            {label} <span>{pluralize('source', sourcesCount, true)}</span>
          </div>
        </Group>
      </Flex>
    </Combobox.Option>
  );
};

const View = memo(function View(props) {
  const {
    form,
    optionsData,
    planInfo,
    handleCheckboxChange,
    wikisToAdd,
    setWikisToAdd,
    isFetchingTeams,
    customApi,
    handleCustomApiUpdate,
    isUpdating,
    handleCloseFooter,
    resetOptionsData,
    setResetOptionsData,
    onManageCustomApi,
    handleRadioChange
  } = props;

  return (
    <div className={styles.wrapper}>
      <Text size='lg' fw='bold'>
        Configure your Custom API
      </Text>
      {customApi && <CustomizedApiInfo customApi={customApi} />}
      <Flex direction='column' gap={20} mt={10}>
        <div>
          <TextInput
            label='Name'
            description='Custom API Name'
            placeholder='Untitled'
            classNames={{
              input: styles.inputText,
              label: styles.inputLabel,
              description: styles.subTitle
            }}
            {...form.getInputProps('name')}
          />
        </div>
        <div className={styles.searchContainer}>
          <Text fz={16} fw={500}>
            Search
          </Text>
          <div className={styles.subTitle}>
            When someone asks a question through the API, Albus should answer using:
          </div>
          {!form.values.search.ai && !form.values.search.training && (
            <div className={styles.errorMessage}>
              <ErrorIcon />
              <span>
                To set up the API, you need to select at least one data source for search.
              </span>
            </div>
          )}
          <div className={styles.checkboxWrapper}>
            <Checkbox
              radius='sm'
              size='sm'
              label='Wiki data'
              description='Answer using data sources like company policies, Slack conversations and team documentation across 30+ workplace apps including Google Drive, Slack and Jira.'
              classNames={{
                root: styles.checkbox,
                label: styles.checkboxLabel,
                description: styles.description,
                input: classnames({ [styles.checked]: form.values.search.training })
              }}
              checked={form.values.search.training}
              onChange={(event) => handleCheckboxChange(event, 'training')}
            />
            {form.values.search.training && (
              <>
                <div>
                  <Radio.Group
                    defaultValue='deep'
                    value={form.values.searchFlow}
                    onChange={(event) => handleRadioChange(event)}
                    classNames={{
                      root: styles.radioGroupRoot
                    }}
                  >
                    <Radio
                      value='quick'
                      label='Quick search'
                      description='Albus runs a quick search. He is fast but sometimes does not find information.'
                      color='green'
                      classNames={{
                        label: styles.radioLabel,
                        description: styles.radioDescription,
                        radio: classnames({
                          [styles.radioCheckedIcon]: form.values.searchFlow === 'quick'
                        })
                      }}
                    />
                    <Radio
                      value='deep'
                      label='Deep search'
                      description=' Albus runs multiple iterations and layers of search to look for more precise information. He writes better answers but takes more time.'
                      color='green'
                      mt={20}
                      classNames={{
                        label: styles.radioLabel,
                        description: styles.radioDescription,
                        radio: classnames({
                          [styles.radioCheckedIcon]: form.values.searchFlow === 'deep'
                        })
                      }}
                    />
                  </Radio.Group>
                </div>
                {form.values.searchFlow === 'deep' && (
                  <div>
                    <Checkbox
                      radius='sm'
                      size='sm'
                      label='Agents'
                      description={
                        <Text size='xs'>
                          Answer complex questions more effectively using agents each equipped with
                          special skills like understanding questions, summarizing contexts, writing
                          code and more.
                          <Box mt={10}>
                            <b>Note:</b> Albus takes more time to answer questions with agents.
                            Please do NOT enable agents in custom APIs set up for apps like Google
                            Sheets where the response timeout is short.
                          </Box>
                        </Text>
                      }
                      classNames={{
                        root: styles.checkboxAgents,
                        label: styles.checkboxLabel,
                        description: styles.description,
                        input: classnames({ [styles.checked]: form.values.useAgent })
                      }}
                      checked={form.values.useAgent}
                      onChange={(event) => {
                        form.setFieldValue('useAgent', event.target.checked);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <Tooltip
              w={274}
              disabled={planInfo.hasSubscription}
              label='To enable AI search capabilities, upgrade to a paid plan.'
            >
              <Checkbox
                radius='sm'
                size='sm'
                label={`Online data up to ${LATEST_MODEL_UPDATE_YEAR}`}
                description={`Answer questions using GPT-4o that is trained on online, public data up to ${LATEST_MODEL_UPDATE_MONTH}.`}
                disabled={!planInfo.hasSubscription}
                classNames={{
                  root: styles.checkbox,
                  label: classnames(
                    { [styles.disabled]: !planInfo.hasSubscription },
                    styles.checkboxLabel
                  ),
                  description: classnames(
                    { [styles.disabled]: !planInfo.hasSubscription },
                    styles.description
                  ),
                  input: classnames({ [styles.checked]: form.values.search.ai })
                }}
                checked={form.values.search.ai}
                onChange={(event) => handleCheckboxChange(event, 'ai')}
              />
            </Tooltip>
          </div>
        </div>
        {form.values.search.training && (
          <div className={styles.selectWikiContainer}>
            <Text fz={16} fw={500}>
              Select wikis
            </Text>
            <div className={styles.subTitle}>
              Select wikis this API should refer to for answering incoming questions.
            </div>

            {form.errors.wikis && (
              <div className={styles.errorMessage}>
                <ErrorIcon />
                <span>{form.errors.wikis}</span>
              </div>
            )}
            <div className={styles.multiSelectWrapper}>
              <CustomOptionMultiSelect
                optionsData={optionsData}
                optionValue={wikisToAdd}
                onChange={(values) => {
                  form.setFieldValue('wikis', values);
                  setWikisToAdd(values);
                }}
                placeholder='Search for wikis'
                CustomOption={SelectItem}
                withinPortal={false}
                pillsInputStyles={styles.pillsInput}
                pillsInputLabelStyles={styles.subTitle}
                optionsLoading={isFetchingTeams || resetOptionsData || !optionsData.length}
                resetOptionsData={resetOptionsData}
                setResetOptionsData={setResetOptionsData}
              />
            </div>
          </div>
        )}
        <div>
          <Textarea
            name='persona'
            classNames={{
              input: styles.personaInputText,
              label: styles.inputLabel,
              description: styles.subTitle
            }}
            label={
              <Flex gap='4'>
                Persona
                <Text c='dimmed' size='xs'>
                  (optional)
                </Text>
              </Flex>
            }
            resize='vertical'
            minRows={3}
            description="Personas are the feature that changes the way Albus responds. You can alter the bot's personality, tone, and communication style."
            placeholder='Be professional and concise, emphasizing a formal tone suitable for business interactions'
            {...form.getInputProps('persona')}
          />
        </div>
        <div>
          <CustomApiKeySection
            customApiExists={!!customApi}
            customApiForm={form}
            onManageCustomApi={onManageCustomApi}
          />
        </div>
      </Flex>
      <FooterConfirmationBar show={customApi && form.isDirty()}>
        <Group justify='left'>
          <Button
            className={styles.primaryCta}
            onClick={form.onSubmit(() => {
              handleCustomApiUpdate();
            })}
            loading={isUpdating}
          >
            Save
          </Button>
          <Button className={styles.secondaryCta} onClick={handleCloseFooter}>
            Cancel
          </Button>
        </Group>
      </FooterConfirmationBar>
    </div>
  );
});

SelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  sourcesCount: PropTypes.number.isRequired
};

View.defaultProps = {
  customApi: null
};

View.propTypes = {
  form: PropTypes.object.isRequired,
  optionsData: PropTypes.array.isRequired,
  planInfo: PropTypes.object.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  wikisToAdd: PropTypes.array.isRequired,
  setWikisToAdd: PropTypes.func.isRequired,
  isFetchingTeams: PropTypes.bool.isRequired,
  customApi: PropTypes.shape({
    id: PropTypes.string,
    organisationId: PropTypes.string,
    name: PropTypes.string,
    search: PropTypes.shape({
      ai: PropTypes.bool,
      training: PropTypes.bool
    }),
    entryPoint: PropTypes.string,
    wikis: PropTypes.arrayOf(PropTypes.object),
    persona: PropTypes.string
  }),
  handleCustomApiUpdate: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  handleCloseFooter: PropTypes.func.isRequired,
  resetOptionsData: PropTypes.bool.isRequired,
  setResetOptionsData: PropTypes.func.isRequired,
  onManageCustomApi: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired
};

export default View;
