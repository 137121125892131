import PropTypes from 'prop-types';
import { Badge, Flex, Image, Box, Tooltip, Text } from '@mantine/core';
import { ReactComponent as SupportActionsIcon } from 'assets/icons/support-actions-icon.svg';
import Arrow from 'assets/icons/new-arrow.svg';
import { ACTIONS_SUPPORTED_CONNECTORS } from 'pages/ControlPanel/constants';
import InfoIcon from 'assets/icons/info-gray.svg';
import styles from './styles.module.scss';

const ConnectorCard = ({ conn, setSelectedConnection }) => {
  const isActionsPage = window.location.pathname.includes('/actions');

  return (
    <div key={conn.title} onClick={() => setSelectedConnection(conn)} className={styles.item}>
      <div className={styles.itemName}>
        <img src={conn.icon} width={48} alt='connector icon' loading='lazy' />
        {conn.isConnected && <div className={styles.connectedTag}>Connected</div>}
      </div>
      <div className={styles.titleSubtitleWrapper}>
        <div className={styles.content}>
          <span className={styles.title}>{conn.title} </span>
        </div>
        {conn.subTitle && (
          <Tooltip
            label={
              <Box className={styles.tooltipContent}>
                <Text className={styles.tooltipTitle}>Supported formats:</Text>
                <Text>{conn.subTitle}</Text>
              </Box>
            }
            position='top-start'
            arrowPosition='side'
            arrowOffset={10}
            arrowSize={10}
            withArrow
            multiline
            width={250}
            transitionProps={{ transition: 'fade', duration: 200 }}
          >
            <Flex className={styles.tooltipIconContainer}>
              <Image src={InfoIcon} alt='supported formats' className={styles.infoIcon} />
            </Flex>
          </Tooltip>
        )}
      </div>
      <Flex w='100%'>
        {ACTIONS_SUPPORTED_CONNECTORS.includes(conn.name) && !isActionsPage && (
          <Badge className={styles.supportActionsBadge} color='#EFEFEF'>
            <Flex gap={5} align='center' justify='center'>
              <SupportActionsIcon />
              Supports Actions
            </Flex>
          </Badge>
        )}
        <Image ml='auto' src={Arrow} h={14} w={21} alt='arrow icon' loading='lazy' />
      </Flex>
    </div>
  );
};

ConnectorCard.propTypes = {
  conn: PropTypes.object.isRequired,
  setSelectedConnection: PropTypes.func.isRequired
};

export default ConnectorCard;
