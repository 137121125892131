import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Center, Text, Button } from '@mantine/core';
import userApi from 'redux/services/user';
import { useGetConnectionStatusQuery } from 'redux/services/settings';
import { ReactComponent as TrailExpiryImage } from 'assets/trail-expiry.svg';
import { CONNECTION_SYNC_STATUS, SUPER_QUERY_CONNECTORS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/formatters';
import styles from './styles.module.scss';

const ProviderConnectionStatusModal = (props) => {
  const { provider, status } = props;
  const [showModal, setShowModal] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const dispatch = useDispatch();

  const isSuperQueryConnector = SUPER_QUERY_CONNECTORS.includes(provider);
  const { data: { recordsFetchedCount, syncStatus } = {}, error } = useGetConnectionStatusQuery(
    provider,
    {
      skip:
        !isSuperQueryConnector || status !== CONNECTION_SYNC_STATUS.IN_PROGRESS || connectionError,
      pollingInterval: status === CONNECTION_SYNC_STATUS.IN_PROGRESS ? 5000 : undefined
    }
  );
  error?.message && setConnectionError(error.message);
  if (syncStatus === CONNECTION_SYNC_STATUS.COMPLETED) {
    dispatch(userApi.util.invalidateTags(['User']));
  }

  return (
    <>
      {showModal ? (
        <Modal
          centered
          opened={showModal}
          withCloseButton={false}
          size='xl'
          onClose={() => setShowModal(false)}
          classNames={{ root: styles.root }}
        >
          <Center>
            <TrailExpiryImage />
          </Center>
          {status === CONNECTION_SYNC_STATUS.FAILED ? (
            <Text className={styles.content}>
              Albus could not connect with your {capitalizeFirstLetter(provider)} account.
              Disconnect and connect with your
              {capitalizeFirstLetter(provider)} account again in&nbsp;
              <span className={styles.greenText}>Settings &gt; My connections.</span>
            </Text>
          ) : (
            <Text className={styles.content}>
              Albus is fetching items in your {capitalizeFirstLetter(provider)} account.
              {isSuperQueryConnector &&
                ` ${recordsFetchedCount ?? 0} items done, looking for more…`}
              <br />
              Note: once all items are fetched, you can select which items you’d like to add to your
              wiki as sources.
            </Text>
          )}
          <Center>
            <Button
              className={styles.primaryBtn}
              onClick={() => {
                dispatch(userApi.util.invalidateTags(['User']));
                setShowModal(false);
              }}
            >
              Close
            </Button>
          </Center>
        </Modal>
      ) : null}

      <Button
        className='primaryBtn'
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        Add source
      </Button>
    </>
  );
};

ProviderConnectionStatusModal.propTypes = {
  provider: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default ProviderConnectionStatusModal;
