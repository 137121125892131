import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Modal, Text, Title } from '@mantine/core';
import { useGetTeamsQuery } from 'redux/services/settings';
import InformationContainer from '../LeftPanel/View';
import InputTab from 'pages/Integrations/Connector/Web/AddSource/Components/InputTab/View';
import AddSourceButton, {
  AddSourceButtonUI
} from 'pages/Integrations/Connector/Components/AddSourceButton';
import { ReactComponent as HandriseEmoji } from 'assets/icons/handrise-emoji.svg';
import { PROVIDERS } from 'utils/constants';
import { STEPS } from 'pages/OnBoarding/constants';
import TABS from 'pages/Integrations/Connector/Web/constants';
import styles from './styles.module.scss';

const AddASource = ({ provider, handleStepChange }) => {
  const [showAddModal, setShowModal] = useState(false);
  const isInternetProvider = provider === PROVIDERS.WEB;
  const { data: { teams = [] } = { teams: [] } } = useGetTeamsQuery();
  const teamId = teams?.find((team) => team.name === 'Company wiki')?.teamId;
  const [handler, setHandler] = useState(TABS.WEBSITE);

  const addSourceSuccessCallback = () => {
    setShowModal(false);
    handleStepChange(STEPS.ASK_A_QUESTION);
  };

  function clickCallback() {
    handleStepChange(STEPS.ASK_A_QUESTION);
  }

  function handleBackButton() {
    handleStepChange(STEPS.CONNECT_AN_APP, undefined, true);
  }

  return (
    <>
      {showAddModal && (
        <Modal
          opened={true}
          onclose={() => setShowModal(false)}
          centered
          size='max-content'
          radius={4}
          onClose={() => setShowModal(false)}
          className='mobile-modal'
        >
          <InputTab
            handler={handler}
            setHandler={setHandler}
            addSourceSuccessCallback={addSourceSuccessCallback}
            teamIdFromProps={teamId}
          />
        </Modal>
      )}
      <div className={styles.wrapper}>
        <InformationContainer step={STEPS.ADD_A_SOURCE} />
        <div className={styles.container}>
          <Title className={styles.title}>
            <HandriseEmoji /> You connected Albus to your app, but wait, we&apos;ve got work left!
          </Title>
          <Text className={styles.subText}>
            The next step is to add sources for Albus to absorb information from. <br />
            Once added, Albus will read the sources, absorb the information and remember it!
          </Text>
          <Flex className={styles.buttonContainer}>
            <Button onClick={handleBackButton} className={styles.backButton}>
              Back
            </Button>
            {!isInternetProvider && (
              <AddSourceButton
                isOnBoarding={true}
                providerFromProp={provider}
                clickCallback={clickCallback}
                teamIdFromProps={teamId}
              />
            )}

            {isInternetProvider && <AddSourceButtonUI handleAddSource={() => setShowModal(true)} />}
          </Flex>
        </div>
      </div>
    </>
  );
};

AddASource.defaultProps = {
  provider: ''
};

AddASource.propTypes = {
  provider: PropTypes.string,
  handleStepChange: PropTypes.func.isRequired
};

export default AddASource;
