import { matchRoutes } from 'react-router-dom';
import CustomSourcesIcon from 'assets/icons/custom_sources.svg';
import { CONNECTOR_LIST } from 'pages/ControlPanel/constants';
import localStorage from './localStorage';
import { NAVBAR_ROUTES, PRIVILEGE_CHART, USER_TYPE } from './constants';

/**
 * Function for getting singular or plural form of verb
 *
 * @example
 * maybePluralize(1, 'user')  ==> user
 * maybePluralize(5, 'user')  ==> users
 * maybePluralize(3, 'fox', [''],'es') ==> foxes
 * maybePluralize(4, 'teammate', ['is','are'])  ==> teamates are
 * maybePluralize(1, 'teammate', ['is','are'])  ==> teamate is

 */

const getSuffix = (count, suffix) => (count !== 1 ? suffix : '');

export const maybePluralize = (count, noun, auxillaryVerb = [''], suffix = 's') =>
  `${noun}${getSuffix(count, suffix)} ${
    auxillaryVerb.length === 2 && count !== 1 ? auxillaryVerb[1] : auxillaryVerb[0]
  }`;

export const returnOtherIntegrationsIcon = (providerName) => {
  const icon =
    CONNECTOR_LIST.find((connection) => connection.name === providerName)?.icon ||
    CustomSourcesIcon;
  return icon;
};

export const fetchPostApiCaller = async (endpoint, payload) => {
  //use case: for 302 redirect response code.
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getToken()
    },
    redirect: 'follow',
    body: JSON.stringify(payload)
  };
  try {
    const response = await fetch(`${import.meta.env.VITE_API_URL}${endpoint}`, requestOptions);
    if (response.ok) {
      window.open(response.url, '_self');
    }
  } catch (error) {
    error.toast(error);
  }
};

export function canAccessRoute(route, userType, analyticsUrl, planInfo) {
  if (!analyticsUrl && route === NAVBAR_ROUTES.ACTIVITY_LOGS) {
    return false;
  }

  if (planInfo?.isTrialExpired && route !== NAVBAR_ROUTES.ASK_ALBUS) {
    return false;
  }

  if (!userType || !route || userType === USER_TYPE.COMPANY_ADMIN) {
    return true;
  }

  let matchedRoutes = matchRoutes(PRIVILEGE_CHART[userType], route);
  if (
    (userType === USER_TYPE.TEAM_ADMIN || userType === USER_TYPE.USER) &&
    route === NAVBAR_ROUTES.BILLING
  ) {
    return true;
  }

  return !!matchedRoutes?.length;
}
