import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { func, string } from 'prop-types';
import {
  Box,
  Button,
  Flex,
  Image,
  Loader,
  Menu,
  Text,
  UnstyledButton,
  Tooltip
} from '@mantine/core';
import EmptyState from '../Connector/Components/EmptyState';
import { useFetchProvidersQuery } from 'redux/services/integrations';
import { updateWikiData } from 'redux/features/connectionSlice';
import ActionIcon from 'assets/icons/3-v-dots.svg';
import InfoIcon from 'assets/icons/info-gray.svg';
import { maybePluralize } from 'utils/utilities';
import { NAVBAR_ROUTES } from 'utils/constants';
import { CONNECTOR_CATEGORIES_LIST } from 'pages/ControlPanel/constants';
import styles from '../integrations.module.scss';

const IntegrationList = (props) => {
  const {
    handleDetailsClick,
    teamId,
    searchTerm,
    setShowAddConnectionsCTA,
    editDescriptionClickHandler
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: integrationsData = [],
    isLoading,
    isFetching
  } = useFetchProvidersQuery(
    { teamId },
    {
      skip: !teamId
    }
  );
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const [integrationsList, setIntegrationList] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const { connections } = useSelector((state) => state.connections);

  useEffect(() => {
    if (!isLoading) {
      setIntegrations(integrationsData);
      dispatch(updateWikiData(integrationsData));
    }
  }, [integrationsData]);

  useEffect(() => {
    if (!isLoading && !userLoading) {
      const sortedIntegrationList = connections
        .filter(
          (conn) =>
            conn.isConnected || !!integrationsData?.find((p) => p.provider === conn.name)?.sources
        )
        .sort((a, b) => {
          const aIsConnected = a.isConnected;
          const bIsConnected = b.isConnected;
          const aHasSources = a.docCount >= 0;
          const bHasSources = b.docCount >= 0;

          if (aHasSources !== bHasSources) {
            return aHasSources ? -1 : 1;
          }

          if (aIsConnected !== bIsConnected) {
            return aIsConnected ? -1 : 1;
          }

          return a.title.localeCompare(b.title);
        });

      const listWithoutSchedulingConnectors = sortedIntegrationList.filter(
        (listElement) => listElement.category !== CONNECTOR_CATEGORIES_LIST.SCHEDULING
      );
      setIntegrationList(listWithoutSchedulingConnectors);
      setShowAddConnectionsCTA(!!listWithoutSchedulingConnectors.length);
    }
  }, [integrations, user]);

  const filteredIntegrationList = searchTerm
    ? integrationsList.filter((listElement) =>
        listElement.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : integrationsList;

  if (isLoading || isFetching) {
    return (
      <Flex justify='center' align='center' h='60vh' w='100%'>
        <Loader />
      </Flex>
    );
  }

  if (!integrationsList?.length) {
    return (
      <EmptyState textStyles={styles.emptyStateTitle} text='No connections available' hideButton>
        <p className={styles.emptyStateText}>
          Connect Albus to your favorite apps to absorb information for your wiki
        </p>
        <Button
          mt={20}
          onClick={() => navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS)}
          className='primaryBtn'
          id='add-connection'
        >
          Add connections
        </Button>
      </EmptyState>
    );
  }

  return filteredIntegrationList.map((connector) => (
    <Flex
      key={connector.title}
      onClick={() => handleDetailsClick(connector)}
      className={styles.integrationContainer}
      align='center'
      justify='space-between'
      direction='row'
      shadow='sm'
    >
      <Box className={styles.integrationBox}>
        <Flex w='100%' justify='space-between' className={styles.integrationBoxContainer}>
          <Flex align='center' justify='center' className={styles.connectorContainer}>
            <Image src={connector.icon} className={styles.integrationImage} />
            <Flex align='center' justify='center' className={styles.connectorText}>
              <Text mr={10} classNames={{ root: styles.title }}>
                {connector.title}
              </Text>
              {connector.subTitle && (
                <Tooltip
                  label={
                    <Box className={styles.tooltipContent}>
                      <Text className={styles.tooltipTitle}>Supported formats:</Text>
                      <Text>{connector.subTitle}</Text>
                    </Box>
                  }
                  position='top-start'
                  arrowPosition='side'
                  arrowOffset={10}
                  arrowSize={10}
                  withArrow
                  multiline
                  width={250}
                  transitionProps={{ transition: 'fade', duration: 200 }}
                >
                  <Flex className={styles.tooltipIconContainer}>
                    <Image src={InfoIcon} alt='supported formats' className={styles.infoIcon} />
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          </Flex>
          <Text w={100} classNames={{ root: styles.sourceCountText }}>
            {connector.docCount || 0} {maybePluralize(connector.docCount, 'source')}
          </Text>
        </Flex>
      </Box>
      <Menu>
        <Menu.Target>
          <UnstyledButton
            pl={20}
            pr={20}
            onClick={(e) => e.stopPropagation()}
            className={styles.mobileMenu}
          >
            <Image src={ActionIcon} alt='action icon' width={4} />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={(e) => editDescriptionClickHandler(e, connector)}>
            Edit description
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  ));
};

IntegrationList.propTypes = {
  handleDetailsClick: func.isRequired,
  searchTerm: string,
  teamId: string.isRequired,
  setShowAddConnectionsCTA: func.isRequired,
  editDescriptionClickHandler: func.isRequired
};

export default IntegrationList;
