import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleGrid, Skeleton } from '@mantine/core';
import InfiniteScroll from 'components/InfiniteScroll';
import SourceGridItem from './SourceGridItem';
import { selectSourceIcon } from 'pages/Integrations/utils';

const SourceGrid = (props) => {
  const {
    items,
    totalItems,
    setTotalItems,
    hideCreatedAt,
    hideLastTrained,
    hideUploadedBy,
    showManageResources,
    hideDisconnect,
    domainTitle,
    sourceIcon,
    handleSitemapManageSource,
    pagination,
    pageCount,
    isLoading,
    showHoverEffect,
    hideRetrainNow,
    hideManageAutoRetrain,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showManageSitemapSources,
    showEditName,
    metaPayload,
    selectedSources,
    handleCheckboxCta,
    showCheckbox,
    isAllSourcesSelected,
    checkboxTooltipLabels
  } = props;

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      if (pagination.active === 1) {
        // Only update if items are different
        setTotalItems((prevItems) => {
          const prevItemIds = prevItems.map((prevItem) => prevItem.id);
          const newItemIds = items.map((item) => item.id);
          const hasChanges = newItemIds.some((id) => !prevItemIds.includes(id));

          if (hasChanges) {
            return items;
          }
          return prevItems;
        });
      } else {
        setTotalItems((prevItems) => {
          // Remove updated items from totalItems
          const updatedItemIds = items.map((item) => item.id);
          const filteredItems = prevItems.filter((item) => !updatedItemIds.includes(item.id));
          const hasChanges = items.some(
            (item) => !prevItems.map((prevItem) => prevItem.id).includes(item.id)
          );

          if (hasChanges) {
            return [...filteredItems, ...items];
          }
          return prevItems;
        });
      }
    }
  }, [items, pagination.active, isLoading, setTotalItems]);

  return (
    <div className={pagination.active < pageCount ? 'h90Vh' : 'hAuto'}>
      <InfiniteScroll
        scrollTarget='scroll-target'
        dataLength={totalItems.length}
        hasNext={pagination.active < pageCount}
        onNext={() => {
          pagination.setPage(pagination.active + 1);
        }}
        threshold={50}
      >
        <SimpleGrid>
          {totalItems.map((item) => {
            return (
              <SourceGridItem
                key={item.id}
                item={item}
                hideCreatedAt={hideCreatedAt}
                hideLastTrained={hideLastTrained}
                hideUploadedBy={hideUploadedBy}
                showManageResources={showManageResources}
                hideDisconnect={hideDisconnect}
                domainTitle={domainTitle}
                sourceIcon={selectSourceIcon(item?.type) || item?.sourceIcon || sourceIcon}
                showManageSitemapSources={showManageSitemapSources}
                handleSitemapManageSource={handleSitemapManageSource}
                showHoverEffect={showHoverEffect}
                hideRetrainNow={hideRetrainNow}
                hideManageAutoRetrain={hideManageAutoRetrain}
                messageSourceDeleteModal={messageSourceDeleteModal}
                showManageSourceForWeb={showManageSourceForWeb}
                showEditName={showEditName}
                metaPayload={metaPayload}
                setTotalItems={setTotalItems}
                selectedSources={selectedSources}
                isAllSourcesSelected={isAllSourcesSelected}
                handleCheckboxCta={handleCheckboxCta}
                showCheckbox={showCheckbox}
                checkboxTooltipLabels={checkboxTooltipLabels}
                hideActionMenu={selectedSources.length || isAllSourcesSelected}
              />
            );
          })}
          {isLoading ? <Skeleton height={300} /> : null}
        </SimpleGrid>
      </InfiniteScroll>
    </div>
  );
};

SourceGrid.propTypes = {
  totalItems: PropTypes.array,
  items: PropTypes.array,
  setTotalItems: PropTypes.func,
  hideCreatedAt: PropTypes.bool,
  checkboxTooltipLabels: PropTypes.array,
  hideLastTrained: PropTypes.bool,
  hideUploadedBy: PropTypes.bool,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  domainTitle: PropTypes.string,
  sourceIcon: PropTypes.string,
  handleSitemapManageSource: PropTypes.func,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }),
  pageCount: PropTypes.number,
  isLoading: PropTypes.bool,
  selectedSources: PropTypes.array,
  isAllSourcesSelected: PropTypes.bool,
  handleCheckboxCta: PropTypes.func,
  showCheckbox: PropTypes.bool,
  showHoverEffect: PropTypes.bool,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  showEditName: PropTypes.bool,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

SourceGrid.defaultProps = {
  showManageSitemapSources: false,
  handleSitemapManageSource: () => null,
  totalItems: [],
  setTotalItems: () => null,
  pagination: {},
  isLoading: false,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  showCheckbox: false,
  handleCheckboxCta: () => null,
  selectedSources: [],
  isAllSourcesSelected: false,
  checkboxTooltipLabels: []
};

export default SourceGrid;
