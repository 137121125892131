import mixpanel from 'mixpanel-browser';
import { node } from 'prop-types';
import { useEffect } from 'react';

const EventAnalyticsProvider = ({ children }) => {
  useEffect(() => {
    // Initializing Mixpanel
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: import.meta.env.VITE_NODE_ENV === 'local'
    });

    return () => {
      // Clean up Mixpanel when the component unmounts
      mixpanel.reset();
    };
  }, []);

  return <>{children}</>;
};

EventAnalyticsProvider.propTypes = {
  children: node.isRequired
};

export default EventAnalyticsProvider;
