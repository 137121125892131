import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import authenticate from '@truto/truto-link-sdk';
import userApi from 'redux/services/user';
import { useLazyGetTrutoLinkTokenQuery } from 'redux/services/truto';
import { CONNECTOR_LIST } from 'pages/ControlPanel/constants';
import { SUPER_QUERY_CONNECTORS } from 'utils/constants';

const ReAuthenticateButton = ({ user, provider, reAuthCompleted, handleReAuthCompleted }) => {
  const dispatch = useDispatch();
  const [getTrutoLinkTokenTrigger] = useLazyGetTrutoLinkTokenQuery();

  async function handleReAuthenticate() {
    try {
      const options = {
        integration: provider,
        noBack: true,
        authFormat: 'oauth2',
        skipRapidForm: true,
        iframe: true
      };
      const params = {
        trutoIntegratedAccountId: user?.connections?.find((conn) => provider === conn.provider)
          ?.trutoIntegratedAccountId,
        provider,
        isConnect: false
      };
      const connector = CONNECTOR_LIST.find((conn) => provider === conn.name);

      const { data } = await getTrutoLinkTokenTrigger(params);

      const response = await authenticate(data.linkToken, options);
      if (response?.result === 'success') {
        if (SUPER_QUERY_CONNECTORS.includes(provider)) {
          handleReAuthCompleted();
          setTimeout(() => {
            dispatch(userApi.util.invalidateTags(['User']));
            toast.success(`${connector.title} re-authenticated successfully`);
          }, 3000);
        } else {
          dispatch(userApi.util.invalidateTags(['User']));
          toast.success(`${connector.title} re-authenticated successfully`);
        }
      }
    } catch (error) {
      if (
        error.message === 'Not Found: Error while fetching resource - LIST ticketing/workspaces'
      ) {
        return toast.error('Unable to find the workspace by this name. Please try again.');
      }
      return toast.error(
        'Authentication error: Unable to proceed. Please check your credentials or try again.'
      );
    }
  }

  return (
    <Button
      className='primaryBtn'
      ml={16}
      radius='sm'
      onClick={handleReAuthenticate}
      loading={reAuthCompleted}
    >
      Re-authenticate
    </Button>
  );
};

ReAuthenticateButton.propTypes = {
  user: PropTypes.object.isRequired,
  provider: PropTypes.string.isRequired,
  reAuthCompleted: PropTypes.bool,
  handleReAuthCompleted: PropTypes.func
};

export default ReAuthenticateButton;
