import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Flex, Text, Title, Tooltip } from '@mantine/core';
import { maybePluralize } from 'utils/utilities';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from '../../../billing.module.scss';

const View = (props) => {
  const {
    buyLicense,
    unassignedLicenses,
    licensesAssigned,
    questionsLeft,
    billingCycle,
    billingDate,
    addOnInteractionsLeft,
    isSetToCancel,
    allowedInteractions
  } = props;

  return (
    <Flex direction='column' className={classNames(styles.billingContainer, styles.whiteCard)}>
      <Flex direction='row' justify='space-between' align='center' mb={42}>
        <Title className={styles.pageTitle} order={4}>
          Billing
        </Title>
        <Flex gap={10} className={styles.headerButtons}>
          <Button className={styles.secondaryBtn} onClick={buyLicense}>
            Buy Licenses
          </Button>
          <Button className={styles.secondaryBtn} onClick={buyLicense}>
            Manage Plan
          </Button>
        </Flex>
      </Flex>
      <Flex
        direction='row'
        justify='space-between'
        wrap='wrap'
        gap={20}
        className={styles.paidPlanDetails}
      >
        <div className={styles.billingDetailsContent}>
          <Text className={styles.labelText}>
            Assigned Licenses
            <Tooltip
              w={274}
              label='Assigned Licenses are Licenses assigned to employees in your company. An employee with a License can ask questions, summarize thread and interact with Albus.'
              className={styles.tooltip}
            >
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          </Text>
          <Title className={styles.titleText}> {licensesAssigned} </Title>
        </div>
        <div className={styles.billingDetailsContent}>
          <Text className={styles.labelText}>
            Unassigned Licenses
            <Tooltip
              w={274}
              label='Unassigned Licenses are Licenses that have not been assigned to anyone. These are Licenses you are paying for and are not in use.'
              className={styles.tooltip}
            >
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          </Text>
          <Title className={styles.titleText}> {unassignedLicenses} </Title>
        </div>
        <div className={styles.billingDetailsContent}>
          <Text className={styles.labelText}>
            Questions left
            <Tooltip
              w={290}
              label={`Your ${billingCycle} plan comes with ${
                allowedInteractions || 0
              } ${maybePluralize(
                allowedInteractions || 0,
                'question'
              )}. This resets on ${billingDate}. ${
                addOnInteractionsLeft > 0
                  ? `You have ${addOnInteractionsLeft} add-on questions that are carried over and do not reset.`
                  : ''
              } `}
              className={styles.tooltip}
            >
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          </Text>
          <Flex gap={6} className={styles.questionsLeftWrapper}>
            <Title className={styles.titleText}>{questionsLeft || 0}</Title>
            <div className={styles.questionsLeftText}>
              {isSetToCancel ? `Plan set to cancel on` : `Resets on`} {billingDate}
            </div>
          </Flex>
        </div>
        <div className={styles.billingDetailsContent}>
          <Text className={styles.labelText}>
            Add-on questions
            <Tooltip
              w={274}
              label="When your employees exhaust all questions for your billing cycle, you can add on questions. These questions don't exhaust and are carried over billing cycles."
              className={styles.tooltip}
            >
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          </Text>
          <Title className={styles.titleText}>{Math.max(addOnInteractionsLeft ?? 0, 0)}</Title>
        </div>
      </Flex>
      <Flex
        direction='row'
        justify='center'
        align='center'
        gap={10}
        wrap='wrap'
        className={styles.footerButtons}
      >
        <Button className={styles.secondaryBtn} onClick={buyLicense}>
          Buy Licenses
        </Button>
        <Button className={styles.secondaryBtn} onClick={buyLicense}>
          Manage Plan
        </Button>
      </Flex>
    </Flex>
  );
};

View.defaultProps = {
  licensesAssigned: 0
};

View.propTypes = {
  buyLicense: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired,
  licensesAssigned: PropTypes.number,
  questionsLeft: PropTypes.number.isRequired,
  billingCycle: PropTypes.string.isRequired,
  billingDate: PropTypes.string.isRequired,
  addOnInteractionsLeft: PropTypes.number.isRequired,
  isSetToCancel: PropTypes.bool.isRequired,
  allowedInteractions: PropTypes.number.isRequired
};

export default View;
